import React from "react"

export default function SliderImg(props) {
  return (
    <picture>
      <source
        media="(min-width:1300px)"
        srcSet={props.img.big.srcWebp}
        type="image/webp"
      />
      <source
        media="(min-width:1300px)"
        srcSet={props.img.big.src}
        type="image/jpg"
      />

      <source
        media="(min-width:960px)"
        srcSet={props.img.tall.srcWebp}
        type="image/webp"
      />
      <source
        media="(min-width:960px)"
        srcSet={props.img.tall.src}
        type="image/jpg"
      />

      <source
        media="(min-width:550px)"
        srcSet={props.img.panoramic.srcWebp}
        type="image/webp"
      />
      <source
        media="(min-width:550px)"
        srcSet={props.img.panoramic.src}
        type="image/jpg"
      />

      <source srcSet={props.img.small.srcWebp} type="image/webp" />
      <img src={props.img.small.src} alt={props.alt} />
    </picture>
  )
}
