import React from "react"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"

const containerStyle = {
  width: "100%",
  height: "500px",
}

const center = {
  lat: 41.352566,
  lng: 2.029309,
}

const API_KEY = `${process.env.MAPS_API_KEY}`

function Map() {
  return (
    <LoadScript googleMapsApiKey={API_KEY}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12}>
        <Marker position={center} animation={1} />
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(Map)
