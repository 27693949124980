import React, { useRef, useEffect, useState } from "react"
import axios from "axios"
import ScrollMenu from "react-horizontal-scrolling-menu"

const ArrowLeft = <div className="arrow-prev">{"<"}</div>
const ArrowRight = <div className="arrow-next">{">"}</div>

export default function WorksSlider() {
  const data = useRef([])

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get("https://res.cloudinary.com/semte/raw/list/trabajos.json")
      .then(res => {
        if (res.data.resources.length) {
          axios
            .get(
              "https://res.cloudinary.com/semte/raw/upload/v" +
                res.data.resources[0].version +
                "/" +
                res.data.resources[0].public_id
            )
            .then(res => {
              if (res.data.resources.length) {
                data.current = res.data.resources.map((x, i) => {
                  return (
                    <WorkCard
                      key={i}
                      name={x.obra}
                      description={x.descripcion}
                      duration={x.duracion}
                      finished={x.acabada}
                    />
                  )
                })
                setLoading(false)
              }
            })
        }
      })
  }, [])

  return isLoading ? (
    <div></div>
  ) : (
    <ScrollMenu
      arrowLeft={ArrowLeft}
      arrowRight={ArrowRight}
      data={data.current}
      transition={1}
      itemClassActive={""}
      inertiaScrolling={true}
      inertiaScrollingSlowdown={0.45}
      useButtonRole={false}
    />
  )
}

const WorkCard = props => {
  return (
    <div className="work-card">
      <p className="work-name">{props.name}</p>
      <p className="work-description">{props.description}</p>
      <p className="work-duration">{props.duration}</p>
      {props.finished === "true" ? (
        <p className="work-finished">Acabada</p>
      ) : (
        <p className="work-process">En proceso</p>
      )}
    </div>
  )
}
