import React from "react"
import { Assets } from "../../assets"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"
import SliderImg from "./SliderImg"

export default function Slider() {
  return (
    <div className="sliderContent first" id="about">
      <div className="sliderImg">
        <SliderImg img={Assets.Img1} alt="slider image 1" />
        <SliderImg img={Assets.Img2} alt="slider image 2" />
        <SliderImg img={Assets.Img3} alt="slider image 3" />
      </div>
      <div className="sliderText">
        <div style={{ position: "relative" }}>
          <div className="firstText">
            <h2 style={{ marginBottom: 20 }}>QUIENES SOMOS</h2>
            <p>
              A lo largo de una trayectoria de mas de 20 años, nos hemos
              dedicado a satisfacer a nuestros Clientes. Desde el Particular a
              los Grandes Laboratorios farmacéuticos y a las Grandes
              Instaladoras.
            </p>
            <p>
              SEMTE Instalaciones, se ha reinventado para dar servicio integral
              en todos los ámbitos en cuanto a instalaciones se refiere y con un
              sistema de trabajo bien definido “Eficacia y Flexibilidad”. Este
              sistema, sobradamente reconocido por nuestros clientes y
              colaboradores, nos avala durante los más de 20 años que llevamos
              trabajando en el sector.
            </p>
            <Link to={"/equipo"}>
              <Button size="lg">EQUIPO HUMANO</Button>
            </Link>
          </div>
          <div className="secondText">
            <h2 style={{ marginBottom: 20 }}>NUESTRO OBJETIVO</h2>
            <p>
              Nuestro objetivo principal es poder dar soluciones integrales a
              nuestros clientes, con la capacidad de proyectar y ejecutar
              mediante personal propio instalaciones de climatización,
              tratamientos de aire, ventilación y Agua Caliente Sanitaria.
              Nuestro ámbito de trabajo principal se encuentra en todo el
              territorio nacional. Adaptándonos a las necesidades del proyecto
              pero apoyando al cliente final, buscaremos la solución que más se
              acople a una entente técnico-económica, seleccionando la opción
              más óptima en equipos y ejecución del proyecto.
            </p>
          </div>
          <div className="thirdText">
            <h2 style={{ marginBottom: 20 }}>NUESTRO VALOR AÑADIDO</h2>
            <p>
              Aportamos nuestra experiencia en el sector, visitamos previamente
              los lugares, analizamos minuciosamente los costes, analizamos
              todos los parámetros técnicos para finalmente poder realizar una
              instalación óptima.
            </p>
            <p>
              Todas nuestras intervenciones responden a un objetivo prioritario:
              optimizar los tiempos de ejecución, la gestión de los recursos y
              la relación calidad/precio garantizando soluciones personalizadas
              y eficientes.
            </p>
          </div>
        </div>

        <div className="slider">
          <span
            className="leftArrow"
            onClick={() => {
              let sliderContent = document.querySelector(".sliderContent")
              if (sliderContent.classList.contains("second")) {
                sliderContent.classList.toggle("second")
                sliderContent.classList.toggle("first")
              } else if (sliderContent.classList.contains("third")) {
                sliderContent.classList.toggle("third")
                sliderContent.classList.toggle("second")
              }
            }}
          >
            <img src={Assets.leftArrow} alt=""></img>
          </span>
          <span
            className="rightArrow"
            onClick={() => {
              let sliderContent = document.querySelector(".sliderContent")
              if (sliderContent.classList.contains("first")) {
                sliderContent.classList.toggle("first")
                sliderContent.classList.toggle("second")
              } else if (sliderContent.classList.contains("second")) {
                sliderContent.classList.toggle("second")
                sliderContent.classList.toggle("third")
              }
            }}
          >
            <img src={Assets.rightArrow} alt=""></img>
          </span>
        </div>
      </div>
    </div>
  )
}
