import React from "react"

export default function MediaIcons() {
  return (
    <div>
      <div className="socialMedia">
        <a
          href="https://www.linkedin.com/in/semte-instalaciones-climatización-78b848135"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            width="500"
            height="500"
            viewBox="0 0 500 500"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Linkedin">
              <path
                id="Vector"
                d="M156.258 413.086H87.8983V192.383H156.258V413.086ZM163.078 122.066C163.078 99.968 145.149 82.0312 123.062 82.0312C100.891 82.0312 83.0078 99.968 83.0078 122.066C83.0078 144.173 100.891 162.109 123.062 162.109C145.149 162.109 163.078 144.173 163.078 122.066ZM412.109 291.664C412.109 232.418 399.593 188.477 330.383 188.477C297.127 188.477 274.803 205.109 265.69 222.404H265.625V192.383H199.219V413.086H265.625V303.505C265.625 274.807 272.93 247.005 308.502 247.005C343.59 247.005 344.727 279.823 344.727 305.328V413.086H412.109V291.664ZM500 441.406V58.5938C500 26.2833 473.717 0 441.406 0H58.5938C26.2833 0 0 26.2833 0 58.5938V441.406C0 473.717 26.2833 500 58.5938 500H441.406C473.717 500 500 473.717 500 441.406V441.406ZM441.406 39.0625C452.175 39.0625 460.938 47.8249 460.938 58.5938V441.406C460.938 452.175 452.175 460.938 441.406 460.938H58.5938C47.8249 460.938 39.0625 452.175 39.0625 441.406V58.5938C39.0625 47.8249 47.8249 39.0625 58.5938 39.0625H441.406Z"
                fill="url(#paint0_linear)"
              />
              <g id="black">
                <path
                  id="Vector_2"
                  d="M91.7687 195.78H161.364V405.159H91.7687V195.78Z"
                  fill="black"
                />
                <path
                  id="Vector_3"
                  d="M126.107 167.204H126.567C150.846 167.204 165.943 151.119 165.943 131.016C165.484 110.47 150.846 94.8413 127.022 94.8413C103.201 94.8413 87.6288 110.47 87.6288 131.016C87.6288 151.119 102.763 167.204 126.107 167.204Z"
                  fill="black"
                />
                <path
                  id="Vector_4"
                  d="M269.483 405.159V288.221C269.483 281.947 269.962 275.729 271.794 271.255C276.832 258.731 288.265 245.8 307.506 245.8C332.7 245.8 342.759 265.005 342.759 293.138V405.155H412.371V285.088C412.371 220.78 378.032 190.863 332.225 190.863C295.244 190.863 278.733 211.215 269.483 225.447V226.132H269.028C269.149 225.906 269.346 225.676 269.483 225.447V195.78H199.859C200.793 215.42 199.859 405.155 199.859 405.155H269.483V405.159Z"
                  fill="black"
                />
                <path
                  id="Vector_5"
                  d="M0 0V500H500V0H0ZM469.072 469.072H30.9278V30.9278H469.072V469.072Z"
                  fill="black"
                />
              </g>
            </g>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="250"
                y1="0"
                x2="250"
                y2="500"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2AF598" />
                <stop offset="1" stopColor="#009EFD" />
              </linearGradient>
            </defs>
          </svg>
        </a>
      </div>
      <div className="socialMedia">
        <a
          href="https://www.facebook.com/SEMTE-Instalaciones-540794869449129/?fref=ts"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            width="505"
            height="506"
            viewBox="0 0 505 506"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Facebook">
              <path
                id="Vector"
                d="M445.82 0H59.1797C26.548 0 0 26.548 0 59.1797V445.82C0 478.452 26.548 505 59.1797 505H445.82C478.452 505 505 478.452 505 445.82V59.1797C505 26.548 478.452 0 445.82 0ZM465.547 445.82C465.547 456.698 456.698 465.547 445.82 465.547H333.379V304.775H394.324L404.395 243.623H333.379V201.211C333.379 184.468 346.226 171.621 362.969 171.621H403.408V110.469H362.969C312.742 110.469 272.248 151.155 272.248 201.383V243.623H213.047V304.775H272.248V465.547H59.1797C48.3025 465.547 39.4531 456.698 39.4531 445.82V59.1797C39.4531 48.3025 48.3025 39.4531 59.1797 39.4531H445.82C456.698 39.4531 465.547 48.3025 465.547 59.1797V445.82Z"
                fill="url(#paint0_linear)"
              />
              <g id="black">
                <g id="Group">
                  <path
                    id="Vector_2"
                    d="M441.036 10H62.0136C30.0249 10 4 36.0249 4 68.0136V447.036C4 479.025 30.0249 505.049 62.0136 505.049H441.036C473.025 505.049 499.049 479.025 499.049 447.036V68.0136C499.049 36.0249 473.025 10 441.036 10ZM460.374 447.036C460.374 457.699 451.699 466.374 441.036 466.374H330.81V308.77H390.554L400.426 248.823H330.81V207.246C330.81 190.833 343.404 178.239 359.817 178.239H399.459V118.292H359.817C310.58 118.292 270.884 158.176 270.884 207.415V248.823H212.849V308.77H270.884V466.374H62.0136C51.3507 466.374 42.6757 457.699 42.6757 447.036V68.0136C42.6757 57.3507 51.3507 48.6757 62.0136 48.6757H441.036C451.699 48.6757 460.374 57.3507 460.374 68.0136V447.036Z"
                    fill="black"
                  />
                </g>
              </g>
            </g>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="252.5"
                y1="0"
                x2="252.5"
                y2="505"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2AF598" />
                <stop offset="1" stopColor="#009EFD" />
              </linearGradient>
            </defs>
          </svg>
        </a>
      </div>
      <div className="socialMedia">
        <a
          href="https://www.instagram.com/semteinstalaciones/?hl=es"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            width="500"
            height="500"
            viewBox="0 0 500 500"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Instagram">
              <path
                id="Vector"
                d="M354.758 500H145.242C65.1553 500 0 434.845 0 354.758V145.242C0 65.1553 65.1553 0 145.242 0H354.759C434.845 0 500 65.1553 500 145.242V354.759C500 434.845 434.845 500 354.758 500ZM145.242 39.0625C86.6943 39.0625 39.0625 86.6943 39.0625 145.242V354.759C39.0625 413.306 86.6943 460.938 145.242 460.938H354.759C413.306 460.938 460.938 413.306 460.938 354.758V145.242C460.938 86.6943 413.306 39.0625 354.758 39.0625H145.242ZM250 384.766C175.689 384.766 115.234 324.311 115.234 250C115.234 175.689 175.689 115.234 250 115.234C324.311 115.234 384.766 175.689 384.766 250C384.766 324.311 324.311 384.766 250 384.766ZM250 154.297C197.229 154.297 154.297 197.229 154.297 250C154.297 302.771 197.229 345.703 250 345.703C302.771 345.703 345.703 302.771 345.703 250C345.703 197.229 302.771 154.297 250 154.297ZM367.188 113.281C367.188 124.068 375.932 132.812 386.719 132.812C397.506 132.812 406.25 124.068 406.25 113.281C406.25 102.494 397.506 93.75 386.719 93.75C375.932 93.75 367.188 102.494 367.188 113.281Z"
                fill="url(#paint0_linear)"
              />
              <g id="black">
                <path
                  id="Vector_2"
                  d="M362.015 0H137.979C61.8974 0 0 61.9004 0 137.982V362.018C0 438.103 61.8974 500 137.979 500H362.015C438.103 500 500 438.1 500 362.018V137.982C500.003 61.9004 438.103 0 362.015 0ZM455.641 362.018C455.641 413.641 413.641 455.638 362.018 455.638H137.979C86.3588 455.641 44.3624 413.641 44.3624 362.018V137.982C44.3624 86.3618 86.3588 44.3624 137.979 44.3624H362.015C413.638 44.3624 455.638 86.3618 455.638 137.982V362.018H455.641Z"
                  fill="black"
                />
                <path
                  id="Vector_3"
                  d="M250 121.168C178.958 121.168 121.163 178.964 121.163 250.006C121.163 321.045 178.958 378.837 250 378.837C321.042 378.837 378.837 321.045 378.837 250.006C378.837 178.964 321.042 121.168 250 121.168ZM250 334.472C203.422 334.472 165.525 296.58 165.525 250.003C165.525 203.422 203.42 165.528 250 165.528C296.581 165.528 334.475 203.422 334.475 250.003C334.475 296.58 296.578 334.472 250 334.472Z"
                  fill="black"
                />
                <path
                  id="Vector_4"
                  d="M384.241 83.5521C375.694 83.5521 367.297 87.0124 361.261 93.0753C355.195 99.1086 351.708 107.508 351.708 116.085C351.708 124.635 355.198 133.031 361.261 139.094C367.294 145.127 375.694 148.617 384.241 148.617C392.817 148.617 401.187 145.127 407.25 139.094C413.313 133.031 416.773 124.632 416.773 116.085C416.773 107.508 413.313 99.1086 407.25 93.0753C401.217 87.0124 392.817 83.5521 384.241 83.5521Z"
                  fill="black"
                />
              </g>
            </g>
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="250"
                y1="0"
                x2="250"
                y2="500"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2AF598" />
                <stop offset="1" stopColor="#009EFD" />
              </linearGradient>
            </defs>
          </svg>
        </a>
      </div>
    </div>
  )
}
