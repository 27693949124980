import React from "react"
import { Container, Row, Col, Button, Carousel } from "react-bootstrap"
import { Assets } from "../../assets"
import Map from "../Map"
import { Link } from "gatsby"
import Slider from "./Slider"
import WebPicture from "../WebPicture"
import MediaIcons from "./MediaIcons"
import WorksSlider from "./WorksSlider"
import LazyLoad from "react-lazy-load"

export const Home = props => {
  return (
    <Container fluid>
      <Slider />
      {/*-----------Container (Servicios)-----------------*/}
      <div className="serviciosContainer" id="servicios">
        <div className="serviciosText">
          <h2 style={{ marginBottom: 20 }}>SERVICIOS</h2>
          <p>
            A lo largo de los años Semte Instalaciones ha dado mas valor, si
            cabe, al mantenimiento de las instalaciones efectuadas, logrando así
            mediante un buen mantenimiento correctivo programado, una longevidad
            optima de las instalaciones y a su vez una plena satisfacción del
            cliente.
          </p>
          <p>
            Efectuamos visitas programadas a las instalaciones de Climatización
            antes del cámbio de estación con tal de asegurar un cámbio óptimo de
            las condiciones de trabajo de las instalaciones y así detectar
            posibles anomalías.
          </p>
        </div>
        <div className="serviciosImg">
          <picture>
            <source
              media="(min-width:1440px)"
              srcSet={Assets.Servicios.panoramic.srcWebp}
              type="image/webp"
            />
            <source
              media="(min-width:1440px)"
              srcSet={Assets.Servicios.panoramic.src}
              type="image/jpg"
            />

            <source
              media="(min-width:960px) and (max-width:1440px)"
              srcSet={Assets.Servicios.big.srcWebp}
              type="image/webp"
            />
            <source
              media="(min-width:960px) and (max-width:1440px)"
              srcSet={Assets.Servicios.big.src}
              type="image/jpg"
            />

            <source
              srcSet={Assets.Servicios.panoramic.srcWebp}
              type="image/webp"
            />
            <img
              src={Assets.Servicios.panoramic.src}
              alt="Imagen de servicios"
            />
          </picture>
        </div>
      </div>

      {/*-----------Container (Trabajos)-----------------*/}
      <Row
        className="rowContainer justify-content-center"
        id="trabajos"
        style={{ paddingTop: "60px" }}
      >
        <Col md={8} className="text-center" style={{ maxWidth: "1000px" }}>
          <h2 style={{ marginBottom: 40 }}>TRABAJOS</h2>
          <LazyLoad offsetVertical={600}>
            <Carousel>
              <Carousel.Item>
                <WebPicture
                  className="d-block w-100"
                  src={Assets.CarrImg1.src}
                  srcWebp={Assets.CarrImg1.srcWebp}
                  srcType={Assets.CarrImg1.srcType}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <WebPicture
                  className="d-block w-100"
                  src={Assets.CarrImg2.src}
                  srcWebp={Assets.CarrImg2.srcWebp}
                  srcType={Assets.CarrImg2.srcType}
                  alt="Second slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <WebPicture
                  className="d-block w-100"
                  src={Assets.CarrImg3.src}
                  srcWebp={Assets.CarrImg3.srcWebp}
                  srcType={Assets.CarrImg3.srcType}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <WebPicture
                  className="d-block w-100"
                  src={Assets.CarrImg4.src}
                  srcWebp={Assets.CarrImg4.srcWebp}
                  srcType={Assets.CarrImg4.srcType}
                  alt="Fourth slide"
                />
              </Carousel.Item>
            </Carousel>
          </LazyLoad>
          <Link to="/galeria">
            <Button size="lg" style={{ margin: 20 }}>
              GALERÍA
            </Button>
          </Link>
        </Col>
      </Row>
      <WorksSlider />

      {/*---------------Container (Contact)-------------*/}
      <Row
        className="bg-gray rowContainer"
        id="contacto"
        style={{ paddingTop: "60px" }}
      >
        <Col sm={12} className="text-center">
          <h2 style={{ marginBottom: 20 }}>CONTACTO</h2>
        </Col>
        <Col
          sm={5}
          className="justify-content-center display-flex mailIconContainer"
        >
          <img alt="" src={Assets.mailIcon} className="mailIcon" />
        </Col>
        <Col sm={7}>
          <ul style={{ listStyleType: "none" }}>
            <li className="contactItem">
              <p>
                <a
                  href="https://goo.gl/maps/Zgbwni32P5LE5E2h7"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="" src={Assets.mapIcon} className="contactIcon" />{" "}
                  C/de Josep Romeu i Torello 3, Local 2, Sant Boi de Llobregat
                  08830 Barcelona
                </a>
              </p>
            </li>
            <li className="contactItem">
              <a href="tel:930170466">
                <img alt="" src={Assets.earphoneIcon} className="contactIcon" />{" "}
                93 017 04 66
              </a>
            </li>
            <li className="contactItem">
              <a href="tel:618540697">
                <img
                  alt=""
                  src={Assets.smartphoneIcon}
                  className="contactIcon"
                />{" "}
                618 54 06 97
              </a>
            </li>
            <li className="contactItem">
              <a href="mailto:info@semte.es">
                <img alt="" src={Assets.envelopeIcon} className="contactIcon" />{" "}
                info@semte.es
              </a>
            </li>
          </ul>
          <MediaIcons />
        </Col>
      </Row>

      <LazyLoad height={500} width="100%" offsetVertical={400}>
        <Map />
      </LazyLoad>

      <Row className="justify-content-center display-flex">
        <img
          className="arrowUp"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          alt="Arrow to scroll top"
          src={Assets.arrowUp}
        />
      </Row>
    </Container>
  )
}
