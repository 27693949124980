import React from "react"
import { Layout } from "../components/Layout"
import { Home } from "../components/Home/Home"

function App() {
  return (
    <Layout Jumbo={true}>
      <Home />
    </Layout>
  )
}

export default App
